// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/var/www/vhosts/al-fogher.it/menu.al-fogher.it/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/var/www/vhosts/al-fogher.it/menu.al-fogher.it/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-jsx": () => import("/var/www/vhosts/al-fogher.it/menu.al-fogher.it/src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-menu-js": () => import("/var/www/vhosts/al-fogher.it/menu.al-fogher.it/src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-splash-js": () => import("/var/www/vhosts/al-fogher.it/menu.al-fogher.it/src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/www/vhosts/al-fogher.it/menu.al-fogher.it/.cache/data.json")

